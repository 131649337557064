:root {
  --primary: #17549a;
  --secondary: #221d1e;
  --tertiary: #ec2320;
  --auxiliar: #b2b2b2;
  --layout: #f5f5f5;
  --border-radius: "15px";
  --white: #fff;
  --shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.mb-24 {
  margin-bottom: 24px;
}

.align-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.pt-2 {
  padding-top: 2rem;
}
.align-center {
  align-self: center;
}

.subtitle_dashboard {
  color: var(--primary);
  font-weight: bold;
}

.primary_button {
  width: 8rem;
  margin: 10px;
}

.icon {
  width: 32px;
  height: 32px;
  background-color: var(--primary);
  border-radius: 8px;
  border: solid 1px var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  color: var(--layout);
}

.overflow_x {
  overflow-x: scroll;
}

.alert {
  position: fixed;
  top: 20px;
  right: 10px;
}

.logo-img {
  width: 6rem;
}

.logo-img-mobile {
  width: 4rem;
}

.value {
  display: flex;
  flex-direction: row;
}

.hide {
  display: none;
}

.logo-img-closed {
  width: 4rem;
}

@media (min-width: 200px) and (max-width: 640px) {
  .table-row {
    font-size: 0.7rem !important;
  }

  .table-row td {
    padding: 1px !important;
  }

  .table-row button {
    padding: 1px !important;
  }

  .table-row span {
    font-size: 0.7rem !important;
  }
}
